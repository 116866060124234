<template>
  <div class="dashboard">
    <div class="card-row">
      <Card title="Packages" route="/packages" color="#FF6B6B" />
      <Card title="Hotels" route="/hotels" color="#A0C4FF" />
      <Card title="Homestays" route="/homestays" color="#67D987" />
      <Card title="Bookings" route="/bookings" color="#fdc323" />
    </div>

    <div class="card-row">
      <Card title="Banners" route="/banners" color="#FFA500" />
      <Card title="Pages" route="/pages" color="#E68FAC" />
      <Card title="Categories" route="/categories" color="#6A7CFF" />
      <Card title="Blogs" route="/blogs" color="#8BC34A" />
    </div>

    <div class="title-row">
      <h2>Regions / Activities</h2>
    </div>
    <div class="card-row">
      <Card
        title="Package Activities"
        route="/package-activities"
        color="#cea97c"
      />
      <Card title="Package Regions" route="/package-regions" color="#008365" />
      <Card title="Hotel Regions" route="/hotel-regions" color="#088da5" />
      <Card
        title="Homestay Regions"
        route="/homestay-regions"
        color="#f9a799"
      />
    </div>

    <div class="card-row">
      <Card title="Amenities" route="/amenities" color="#ba7ee2" />
      <Card title="Features" route="/features" color="#015697" />
    </div>

    <div class="title-row">
      <h2>System</h2>
    </div>
    <div class="card-row">
      <Card title="Roles" route="/roles" color="#00a261" />
      <Card title="Users" route="/users" color="#8d989c" />
    </div>

    <div class="title-row">
      <h2>Settings</h2>
    </div>
    <div class="card-row">
      <Card title="Top Deals" route="/topdeals" color="#fdc323" />
      <Card title="Seo Pages" route="/seo-pages" color="#67D987" />
      <Card title="Notices" route="/notices" color="#A0C4FF" />
      <Card title="Settings" route="/settings" color="#FF6B6B" />
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";

export default {
  components: {
    Card,
  },
};
</script>

<style>
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
}

.card-row {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.title-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  position: relative;
  right: 20%;
}
</style>
