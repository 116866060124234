<template>
  <div class="card" :style="cardStyle" @click="navigateToRoute">
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    route: String,
    color: String,
  },
  methods: {
    navigateToRoute() {
      this.$router.push(this.route);
    },
  },
  computed: {
    cardStyle() {
      return {
        backgroundColor: this.color,
      };
    },
  },
};
</script>

<style>
.card {
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 15px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

h3 {
  margin: 0;
  color: white;
  font-size: 18px;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
</style>
